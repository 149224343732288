import { Link } from "gatsby";
import PropTypes from "prop-types";
import * as React from "react";

const NavbarItem = (props) => (
  <Link
    className="navbar-item is-uppercase has-text-white"
    to={props.page.link}
  >
    {props.page.name}
  </Link>
);

const NavbarItemDropdown = (props) => (
  <div className="navbar-item has-dropdown is-hoverable">
    <a className="navbar-link is-uppercase">{props.page.name}</a>
    <div className="navbar-dropdown">
      {props.page.dropdown.map((child) => (
        <Link
          className="navbar-item is-uppercase"
          key={child.name}
          to={child.link}
        >
          {child.name}
        </Link>
      ))}
    </div>
  </div>
);

const NavbarBurger = (props) => (
  <a
    role="button"
    onClick={props.toggleMenu}
    className={`navbar-burger has-text-white ${
      props.active ? "is-active" : ""
    }`}
    aria-label="menu"
    aria-expanded="false"
    data-target="navbar"
  >
    <span />
    <span />
    <span />
  </a>
);

export default class Navbar extends React.Component<{ siteTitle; menuLinks }> {
  public static propTypes = {
    siteTitle: PropTypes.string,
    menuLinks: PropTypes.array,
  };

  public state = {
    activeMenu: false,
    isTop: true,
  };

  public componentDidMount() {
    document.addEventListener("scroll", this.handleScroll, true);
    this.handleScroll();
  }

  public componentWillUnmount() {
    document.removeEventListener("scroll", this.handleScroll);
  }

  private handleScroll = () => {
    if (window) {
      this.setState({
        activeMenu: this.state.activeMenu,
        isTop: window.scrollY == 0,
      });
    }
  };

  public toggleMenu = () => {
    this.setState({
      activeMenu: !this.state.activeMenu,
      isTop: this.state.isTop,
    });
  };

  public render() {
    let { siteTitle, menuLinks = [] } = this.props;
    let siteTitleLower = siteTitle.toLowerCase();

    let navbarItems = menuLinks.map((page) =>
      page.dropdown ? (
        <NavbarItemDropdown key={page.name} page={page} />
      ) : (
        <NavbarItem key={page.name} page={page} />
      )
    );
    return (
      <nav
        className={`navbar is-fixed-top has-background-primary has-dark-background ${
          this.state.isTop ? "is-top" : ""
        }`}
        role="navigation"
        aria-label="main navigation"
      >
        <div className="container">
          <div className="navbar-brand">
            <Link className="navbar-item brand has-text-white" to="/">
              {siteTitleLower}
            </Link>
            <NavbarBurger
              active={this.state.activeMenu}
              toggleMenu={this.toggleMenu}
            />
          </div>
          <div
            id="navbar"
            className={`navbar-menu has-background-primary ${
              this.state.activeMenu ? "is-active" : ""
            }`}
          >
            <div className="navbar-end">{navbarItems}</div>
          </div>
        </div>
      </nav>
    );
  }
}
