import * as React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

import Navbar from "./navbar";
import "./style.scss";

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          company
          menuLinks {
            name
            link
          }
        }
      }
    }
  `);

  return (
    <>
      <Navbar
        menuLinks={data.site.siteMetadata.menuLinks}
        siteTitle={data.site.siteMetadata.title}
      />
      <div className="page-container">
        <main>{children}</main>
      </div>
      <footer className="footer has-dark-background has-background-secondary">
        <div className="container">
          <div className="columns has-text-centered">
            <div className="column">
              {" "}
              © {data.site.siteMetadata.company} {new Date().getFullYear()}
            </div>
            <div className="column">+44 (0)20 8191 9608</div>
            <div className="column">
              <a href="mailto:info@meaty.io">info@meaty.io</a>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
